import React from "react";
import styled from "styled-components";
import type { TTimeSlot } from "../../types/TimeSlot";
import type { TPickupLocation } from "../../types/PickupPoint";
import { Text } from "../../components/Text";
import { useTranslation } from "react-i18next";
import { Button } from "../../components/Button";
import ShippingIcon from "../../components/AdvantagesSecondlife/icons/Shipping";
import { theme } from "../../components/theme";
import { Link } from "../../components/Link";
import { formatTimeslotDay } from "../../utils/Date";
import { useAppState } from "../../contexts";
// import { Button } from "../../components/Button";

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  border-top: 1px solid #f3f4f6;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  padding: 1.25rem 0;
  border-bottom: 1px solid #f3f4f6;
`;

const LeftSection = styled.div`
  display: flex;
  gap: 1rem;
  min-width: 0;
`;

const Wrapper = styled.div`
  margin-top: ${theme.spacing[1]};
`;

const PUPInfo = styled.div`
  min-width: 0;
  flex: 1;
`;

const Name = styled.p`
  font-size: 0.875rem;
  line-height: 1.5;
  font-weight: 600;
  color: #111827;
`;

const Distance = styled(Link)`
  margin-top: ${theme.spacing[0.5]};
  text-decoration: underline;
  font-size: 0.75rem;
  line-height: 1.25;
  color: #6b7280;
`;

const RightSection = styled.div`
  display: none;

  @media (min-width: 640px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex-shrink: 0;
  }
`;

const ButtonWrapper = styled.p`
  font-size: 0.875rem;
  line-height: 1.5;
  color: #111827;
  margin-top: ${theme.spacing[0.5]};
`;

const LastSeen = styled.p`
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1.25;
  color: #6b7280;
`;

const OnlineWrapper = styled.div`
  margin-top: 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.375rem;
`;

const AvailableIndicator = styled.div`
  padding: 0.25rem;
  background-color: rgba(5, 150, 105, 0.2);
  border-radius: 9999px;
`;

const AvailableIndicatorInner = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  background-color: #059669;
  border-radius: 9999px;
`;

const KmText = styled.p`
  font-size: 0.75rem;
  line-height: 1.25;
  color: #6b7280;
`;

interface Props {
  timeslots_lad: TTimeSlot[];
  timeslots_pups: TPickupLocation[];
}

export default function ListDelivery(props: Props) {
  const { timeslots_lad, timeslots_pups } = props;
  const { t } = useTranslation();
  const { locale } = useAppState();
  const [lang] = locale.split("-");
  if (timeslots_lad.length === 0 && timeslots_pups.length === 0) {
    return (
      <Wrapper>
        <Text weight="bold">{t("Home.map.cta2")}</Text>
        <Button secondary to="/waiting-list">
          {t("Signup.step3.button-waitlist")}
        </Button>
      </Wrapper>
    );
  }

  if (timeslots_lad.length > 0) {
    return (
      <Wrapper>
        <Text weight="bold">
          {t("Home.listDelivery.message")}{" "}
          {timeslots_lad.map((ts, index) => (
            <span key={ts.id}>
              {formatTimeslotDay(ts.day, locale, "long")}
              {timeslots_lad && timeslots_lad.length !== index + 1 && (
                <span>- </span>
              )}
            </span>
          ))}
        </Text>
        <Button secondary to="/sign-up/step-1">
          {t("Signin.noAccount")}
        </Button>
      </Wrapper>
    );
  }

  return (
    <List>
      <Wrapper>
        <Text weight="bold">{t("Home.listDelivery.message")}</Text>
      </Wrapper>

      {timeslots_pups.map((pup) => (
        <ListItem key={pup.name}>
          <LeftSection>
            <ShippingIcon />
            <PUPInfo>
              <Name>{pup.name}</Name>
              <Distance
                href={`https://maps.google.ca/maps?z=15&hl=en&q=${pup.lat},${pup.lng}`}
                target="_blank"
              >
                {`${pup.address}`}
                <br />
                {`${pup.city} ${pup.zip_code}`}
              </Distance>
            </PUPInfo>
            <div>
              {pup.timeslots?.map((ts, index) => (
                <Text weight="bold" element="span" size="xs" key={ts.id}>
                  {formatTimeslotDay(ts.day, locale, "long")}
                  {pup?.timeslots && pup?.timeslots.length !== index + 1 && (
                    <span>- </span>
                  )}
                </Text>
              ))}
            </div>
          </LeftSection>
          <RightSection>
            <OnlineWrapper>
              <AvailableIndicator>
                <AvailableIndicatorInner />
              </AvailableIndicator>
              {pup.distance && (
                <KmText>
                  Distance : {Number(pup?.distance)?.toFixed(2)} km
                </KmText>
              )}
            </OnlineWrapper>
            <ButtonWrapper>
              <Button size="sm" secondary to="/sign-up/step-1">
                {t("Signin.noAccount")}
              </Button>
            </ButtonWrapper>
          </RightSection>
        </ListItem>
      ))}
    </List>
  );
}
