import React, { useState } from "react";
import styled from "styled-components";
import { AddressAutocomplete } from "../../components/Delivery/AddressForm/AddressAutocomplete/AddressAutocomplete";
import { Button } from "../../components/Button";
import { useSearchLADPUPByZip } from "../../hooks/Delivery";
import { theme } from "../../components/theme";
import { Heading } from "../../components/Heading";
import { useTranslation } from "react-i18next";
import ListDelivery from "./ListDelivery";

const Wrapper = styled.div`
  padding: ${theme.spacing[0.5]};
`;

const Container = styled.div``;

const HeadingSecondary = styled(Heading)`
  color: ${theme.colors.base};
  font-size: ${theme.font.size.xxxl};
`;
export default function DoWeDeliverToYou() {
  const { t } = useTranslation();

  const [zip_code, setZipcode] = useState("");
  const [latlng, setLatLng] = useState({
    latitude: undefined,
    longitude: undefined,
  });

  const { data } = useSearchLADPUPByZip(
    zip_code,
    latlng.latitude,
    latlng.longitude
  );
  console.log({ data });

  return (
    <Wrapper>
      <Container>
        <HeadingSecondary center>
          {t("DoWeDeliverAtYourPlace")}
        </HeadingSecondary>
        <AddressAutocomplete
          setValue={(addressComponents) => {
            //@ts-expect-error
            const postalCode = addressComponents.find((component) =>
              component.types.includes("postal_code")
            )?.long_name;
            //@ts-expect-error
            const geoLocation = addressComponents.find((component) =>
              component.types.includes("geolocation")
            );
            const latitude = geoLocation?.lat;
            const longitude = geoLocation?.lng;
            setLatLng({ latitude, longitude });
            setZipcode(postalCode);
          }}
          setLatLng={() => {}}
        />
        {data && (
          <ListDelivery
            timeslots_lad={data?.timeslots_lad}
            timeslots_pups={data?.timeslots_pups}
          />
        )}
      </Container>
    </Wrapper>
  );
}
