import { TDelivery, TDeliveryProfile } from "../../types/Delivery";
import { useQuery } from "react-query";
import config from "../../config";
import axios from "redaxios";
import { TFrequenceEnum, TPickupLocation } from "../../types/PickupPoint";
import { TTimeSlot } from "../../types/TimeSlot";
const pupApiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/pickup-points`;
const deliveryApiUrl = `${config.SERVICES.MARKETPLACE_API_URL}/deliveries`;
export const useDelivery = () => {
  const token = localStorage.getItem("token");

  return useQuery<TDelivery>(
    ["useDelivery", token],
    () =>
      axios
        .get(`${deliveryApiUrl}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => res.data.payload),
    {
      enabled: !!token,
    }
  );
};

/**
 * Get all timeslots by zipcode/area
 * @param zipCode - The zipcode
 * @returns A list of timeslots for the specified zipcode/area
 */
export const useTimeslotsByZipcode = (zipCode: string, province?: string) => {
  const token = localStorage.getItem("token");

  return useQuery<TTimeSlot[]>(
    ["useTimeslotsByZipcode", zipCode],
    () =>
      axios
        .get(
          `${deliveryApiUrl}/timeslots/zipcode?zip_code=${zipCode}&province=${province}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => res.data.payload),
    {
      enabled: !!token && !!zipCode,
    }
  );
};

export const useSearchLADPUPByZip = (zip_code: string, latitude?: number, longitude?: number) => {

  return useQuery<{ timeslots_lad: TTimeSlot[], timeslots_pups: TPickupLocation[] }>(
    ["useSearchLADPUPByZip", zip_code, latitude, longitude],
    () =>
      axios
        .get(
          `${deliveryApiUrl}/timeslots/zipcode/search?zip_code=${zip_code}&latitude=${latitude}&longitude=${longitude}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => res.data.payload),
    {
      enabled: !!zip_code,
    }
  );
};
/**
 * Get pickup locations by distance
 * @param lattitude - The lattitude of the user
 * @param longitude - The lattitude of the user
 * @param limit - The max number of returned pups
 * @param maxRange - The max range for a pup to be returned
 * @param additionalFields - The additional fields to return
 * @returns The product list
 */
export const useGetByDistance = (
  lattitude: number,
  longitude: number,
  limit: number,
  maxRange = 50,
  additionalFields: string[] = []
) => {
  return useQuery<TPickupLocation[] & TDelivery>(["useGetByDistance"], () =>
    // TODO: I don't understand why we are using a PUT. We don't write anything in the db...
    axios
      .put(
        `${pupApiUrl}/distance`,
        {
          lattitude,
          longitude,
          limit,
          maxRange,
          additionalFields,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => res.data.payload)
  );
};

export const useGetAllPup = () => {
  return useQuery<TPickupLocation[] & TDelivery>(["useGetAllPup"], () =>
    axios
      .get(`${pupApiUrl}/opens`, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res.data.payload)
  );
};

/**
 * create or update PUP
 * @param userId - user id
 * @param pupId -pup id
 * @param timeslotId - timeslot id
 * @returns The Subscription object
 */
export const useCreatePUPDelivery = (payload: {
  ppid: string;
  timeslotId: string;
  frequence: TFrequenceEnum;
}) => {
  const token = localStorage.getItem("token");
  const { ppid, timeslotId, frequence } = payload;
  return axios
    .post(
      `${deliveryApiUrl}/PUP`,
      { ppid, timeslotId, frequence },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};

/**
 * Create the user LAD delivery
 * @returns The user LAD delivery
 */
export const useCreateLADDelivery = (payload: {
  address: Pick<
    TDeliveryProfile,
    | "address"
    | "address2"
    | "city"
    | "province"
    | "zip_code"
    | "country"
    | "note"
  >;
  timeslotId: string;
  frequence: TFrequenceEnum;
}) => {
  const token = localStorage.getItem("token");
  const { address, timeslotId, frequence } = payload;
  return axios
    .post(
      `${deliveryApiUrl}/LAD`,
      { ...address, timeslotId, frequence },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((res) => res.data.payload);
};
